
import * as ipxRuntime$_9TjbMFv4aGrmPmLtvxHhtS9Bjxv1zDTqgo2ac6UnImE from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as mediaStreamRuntime$CiYop1RRFicwN60vy9wDkDAaHNbFl5dKlvdMezLETm0 from '/app/app/providers/media-stream.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "modifiers": {
        "quality": 90,
        "format": "webp"
      }
    }
  },
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$_9TjbMFv4aGrmPmLtvxHhtS9Bjxv1zDTqgo2ac6UnImE, defaults: {"maxAge":31536000} },
  ['mediaStream']: { provider: mediaStreamRuntime$CiYop1RRFicwN60vy9wDkDAaHNbFl5dKlvdMezLETm0, defaults: {"format":"webp","width":100,"height":100,"fit":"contain","position":"entropy","background":"transparent","trimThreshold":5,"path":""} }
}
        