import type { ModuleOptions } from '../runtime/cookies/types'

export default {
  "closeModalOnClickOutside": false,
  "cookies": {
    "necessary": [
      {
        "id": "n",
        "name": "cookies.necessary",
        "description": "cookies.necessary_description",
        "targetCookieIds": [
          "i18n_redirected",
          "ncc_c",
          "ncc_e"
        ]
      },
      {
        "id": "functionality_storage",
        "name": "cookies.functionality_storage",
        "description": "cookies.functionality_storage_description",
        "targetCookieIds": []
      }
    ],
    "optional": [
      {
        "id": "ad_storage",
        "name": "cookies.ad_storage",
        "description": "cookies.ad_storage_description",
        "targetCookieIds": []
      },
      {
        "id": "ad_user_data",
        "name": "cookies.ad_user_data",
        "description": "cookies.ad_user_data_description",
        "targetCookieIds": []
      },
      {
        "id": "ad_personalization",
        "name": "cookies.ad_personalization",
        "description": "cookies.ad_personalization_description",
        "targetCookieIds": []
      },
      {
        "id": "analytics_storage",
        "name": "cookies.analytics_storage",
        "description": "cookies.analytics_storage_description",
        "targetCookieIds": []
      },
      {
        "id": "personalization_storage",
        "name": "cookies.personalization_storage",
        "description": "cookies.personalization_storage_description",
        "targetCookieIds": []
      },
      {
        "id": "security_storage",
        "name": "cookies.security_storage",
        "description": "cookies.security_storage_description",
        "targetCookieIds": []
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/",
    "readonly": false,
    "sameSite": "strict",
    "secure": true
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": true,
  "isCssEnabled": true,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false
} as ModuleOptions