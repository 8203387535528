import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Q575PW3SHn5_V2N_m_1JG3EGjme_bzFjaBE5iRh_hLg from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WhLpWq_7PcURYqa_gjD2HlWHtmWWaBQfTN1REE9m7J8 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5TMaBFCkTLGdyhmGMedqnfmywRmEmMzU5xIc_kWsISg from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_RsJing34NIjMRKMH5XV2NzYX8u2uO0HhJ6gN_Yy3OAs from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ozYf4ZMx9YH2iVHgaFX091sgveO0l51R9b0iMD3rVcM from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_G8Qv7eiEGPPLXF6uChBPWtm7zJ_QJA_cutygdId480c from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nkD4vQmzo22pkIwQERNqB8IyP_s1SOsE1ZHeHZ_yW84 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_WxSsvmFlhKOmIQ8uCOvwyEcEW1kv5idk2TIqbOlbrA4 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_rgW9efoHlJLyoYuWLOXaboQyWkE81KlH1mCADLDY0X0 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.18_magicast@0.3.5/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/app/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import siteConfig_1q71eE_kXhO3rtT7EifVerkS1F3iJSjMFjFsGlCJxdM from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin__rygPpTQTHD6ZwSV_Pxl78zXBS_RcSFU9gDuv2HYrDs from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_f7SMVJUHee5kXp7l0HP_qLKf0r_SzTKAWIZG0Aa0QrE from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_WHL63kyutrL1KokBLzQ6hPP8dAmuF7HhKDtmrqYJVyU from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_WB2exXBqc2RykVvV18t976sp6NRwAjmwrOyNE9_CECs from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_Gwp0yoDN_XRqvNEdOnKsfPsn_bBXgXmygcpOnWgX1K8 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_NIJ_AAPPaaIezdds9v5UBjetYLEdCBoFbKdF_Ib8AAI from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AWtfVI4JMkqj3JZ7YqYtFY8LA3pcj9ayZUyLLMo736s from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_lightningcs_4184f9872812c443c9ababf156f3c596/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import colors_Y1ZfB6yN_GkdiT1ylYRr7RFbO6ZgEHLHpy60ky5kAG0 from "/app/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.27.0_db0@0.3.1_embla-carousel@8.5.2_ioredis@5.6.0_magica_97ae8d8e9bfe1947f4cb52bd8f8ff838/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_k3O6K7xPbZLksPX9eFeByABDUMC2ukizyuhyjkZ4g2M from "/app/runtime/cookies/plugin.ts";
import auth_H8gVM_hLMR4kH70PZQlWNqJIB9Rdr0rL0wFPPsoYoYA from "/app/app/plugins/auth.ts";
import pwa_client_dz_o2T1TlNigVzUPHHmoPG0Xf8XEYsQJyd6SpFj6pXo from "/app/app/plugins/pwa.client.ts";
import setup_2EiPydRvE4hXvMSbzmJ6Lc96jn_E9AtAJl4TFsO4u_M from "/app/app/plugins/setup.ts";
import vee_validate_prVYiciHtOTdOeHtP1m_5DAlIWTV6yazOoBlP_BKG58 from "/app/app/plugins/vee-validate.ts";
import websocket_client_ZhPy2_2UAj0pWmdohDN16DyMwmaeAkAVApkROSEoIrQ from "/app/app/plugins/websocket.client.ts";
import pwa_client_1hxBAaCLTVkgzXl6QgcP9736aZDCW9rocyO7193QBAA from "/app/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.17_jiti@2.4.2_lightnin_45d53cbf30cd97bc00f024b75cd341fa/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_routeRules_fqnkpUHFM4B2PIWwpSQ1TDoOJQzXJ9Gi5qh_QetP2GQ from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_a6QGcXVVOnsH3xgXy8L_mySOvMVhv8gWd0uzY6Um9qE from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_Q575PW3SHn5_V2N_m_1JG3EGjme_bzFjaBE5iRh_hLg,
  unhead_WhLpWq_7PcURYqa_gjD2HlWHtmWWaBQfTN1REE9m7J8,
  router_5TMaBFCkTLGdyhmGMedqnfmywRmEmMzU5xIc_kWsISg,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_RsJing34NIjMRKMH5XV2NzYX8u2uO0HhJ6gN_Yy3OAs,
  navigation_repaint_client_ozYf4ZMx9YH2iVHgaFX091sgveO0l51R9b0iMD3rVcM,
  check_outdated_build_client_G8Qv7eiEGPPLXF6uChBPWtm7zJ_QJA_cutygdId480c,
  chunk_reload_client_nkD4vQmzo22pkIwQERNqB8IyP_s1SOsE1ZHeHZ_yW84,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_WxSsvmFlhKOmIQ8uCOvwyEcEW1kv5idk2TIqbOlbrA4,
  session_client_rgW9efoHlJLyoYuWLOXaboQyWkE81KlH1mCADLDY0X0,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  siteConfig_1q71eE_kXhO3rtT7EifVerkS1F3iJSjMFjFsGlCJxdM,
  inferSeoMetaPlugin__rygPpTQTHD6ZwSV_Pxl78zXBS_RcSFU9gDuv2HYrDs,
  titles_f7SMVJUHee5kXp7l0HP_qLKf0r_SzTKAWIZG0Aa0QrE,
  defaultsWaitI18n_WHL63kyutrL1KokBLzQ6hPP8dAmuF7HhKDtmrqYJVyU,
  i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  switch_locale_path_ssr_WB2exXBqc2RykVvV18t976sp6NRwAjmwrOyNE9_CECs,
  route_locale_detect_Gwp0yoDN_XRqvNEdOnKsfPsn_bBXgXmygcpOnWgX1K8,
  i18n_NIJ_AAPPaaIezdds9v5UBjetYLEdCBoFbKdF_Ib8AAI,
  plugin_AWtfVI4JMkqj3JZ7YqYtFY8LA3pcj9ayZUyLLMo736s,
  colors_Y1ZfB6yN_GkdiT1ylYRr7RFbO6ZgEHLHpy60ky5kAG0,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_k3O6K7xPbZLksPX9eFeByABDUMC2ukizyuhyjkZ4g2M,
  auth_H8gVM_hLMR4kH70PZQlWNqJIB9Rdr0rL0wFPPsoYoYA,
  pwa_client_dz_o2T1TlNigVzUPHHmoPG0Xf8XEYsQJyd6SpFj6pXo,
  setup_2EiPydRvE4hXvMSbzmJ6Lc96jn_E9AtAJl4TFsO4u_M,
  vee_validate_prVYiciHtOTdOeHtP1m_5DAlIWTV6yazOoBlP_BKG58,
  websocket_client_ZhPy2_2UAj0pWmdohDN16DyMwmaeAkAVApkROSEoIrQ,
  pwa_client_1hxBAaCLTVkgzXl6QgcP9736aZDCW9rocyO7193QBAA,
  _0_routeRules_fqnkpUHFM4B2PIWwpSQ1TDoOJQzXJ9Gi5qh_QetP2GQ,
  ssg_detect_a6QGcXVVOnsH3xgXy8L_mySOvMVhv8gWd0uzY6Um9qE
]