
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutrGPy1pFPB0rYqvDyz4_459NXlT_45DouL8moWyegH9oDWz4Meta } from "/app/app/pages/about.vue?macro=true";
import { default as index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta } from "/app/app/pages/index.vue?macro=true";
import { default as searchkbb43gtkc60rGLWak_E_456zSn2BTK1bKkOmKyUR_45kXcMMeta } from "/app/app/pages/search.vue?macro=true";
import { default as vision61mL6ksWghf_45Sa8kzzmTAfx897e1JecckLXDhOGtDPsMeta } from "/app/app/pages/vision.vue?macro=true";
import { default as contact9YIjszO30YiI1EF3Yrjj3j04hZuaoJnKFtoCHkd82XkMeta } from "/app/app/pages/contact.vue?macro=true";
import { default as feedback83JE2kFnKNHHotmsxwzwwAyW_AbO7zjINgvZrTFpFQoMeta } from "/app/app/pages/feedback.vue?macro=true";
import { default as indexv2yp_toDb5EUK8ku_45QAWpOo627WINk_45K3_nAWa_ufBUMeta } from "/app/app/pages/blog/index.vue?macro=true";
import { default as indexSEpBpmIr05bnkEWtSqU0qogkWE4UWGITltf4Uv1oBkEMeta } from "/app/app/pages/cart/index.vue?macro=true";
import { default as terms_45of_45useddy4HtTnpLg1i5X8s1J_45JvLHcEsdFOw0aJsk1bUqv_45EMeta } from "/app/app/pages/terms-of-use.vue?macro=true";
import { default as indexp0Wbi03LwoKhqGNl_45h_oehu2KzdCscs1ZvLTapW5e9MMeta } from "/app/app/pages/account/index.vue?macro=true";
import { default as return_45policyCjJBmTzO24FYa519iBjncfJ5fMrI_hnz6qfOeqfZ0EUMeta } from "/app/app/pages/return-policy.vue?macro=true";
import { default as indextuxD9i30ur3bawJlePRY341J_45J7eXdwpjTspY2246F0Meta } from "/app/app/pages/checkout/index.vue?macro=true";
import { default as cookies_45policy4yeCUyKT8F41YE81TxPQvdUAHbcxBC6cWwxwc0NWDxkMeta } from "/app/app/pages/cookies-policy.vue?macro=true";
import { default as privacy_45policyJLmAnSmMhzVS_A6UmdUL_45am7fZixvpYuV71EHhBQuPgMeta } from "/app/app/pages/privacy-policy.vue?macro=true";
import { default as index07ys5gwdbP_qlZ29ERLnGY0_4582NVAS9qcW_ie0wXWx0Meta } from "/app/app/pages/products/index.vue?macro=true";
import { default as indexCcQrHADyGM_YnfqalwfVg3mEffRBwS7EE0OkdUTzLKYMeta } from "/app/app/pages/account/2fa/index.vue?macro=true";
import { default as why_45microlearningIsOLHgf8Pz3und4XyfkOOGmATPjU_45NZ8a3zkYqTSZfYMeta } from "/app/app/pages/why-microlearning.vue?macro=true";
import { default as indexna0prNz5fiD5A72ljlA_H1Oo_45bTSHVpKhxCxN2EhlgsMeta } from "/app/app/pages/account/help/index.vue?macro=true";
import { default as indexlqlEAwtJLEgVzyR9w7vrMnJH5nmBqiiukdIq9Lw_45MAMMeta } from "/app/app/pages/account/email/index.vue?macro=true";
import { default as indexzvPofsv9gATW6uA4Mx1nrm5FjSbW9UCPffpzuiSLurIMeta } from "/app/app/pages/account/login/index.vue?macro=true";
import { default as _91id_93S_45555RQcxAinqyRMYlylv_OPiO_45gPuFVZqH_45g_41TnQMeta } from "/app/app/pages/account/orders/[id].vue?macro=true";
import { default as index5dk_3LM81ApqKBhiv0p8YDZE12z_vcbpReq0NNYe220Meta } from "/app/app/pages/account/orders/index.vue?macro=true";
import { default as indexF_qeFqbeVaqitGKezogRkCkdrlRriQhb_451bZkVSPIGwMeta } from "/app/app/pages/account/signup/index.vue?macro=true";
import { default as _91slug_93sAt45cIB1eitU_45fDZ_SrCWJ0ZULcpOAXjlalnvyuCD8Meta } from "/app/app/pages/products/[id]/[slug].vue?macro=true";
import { default as newo3ZM2oQzGGnL_nXKMvtAWyIh_45DqvUnno3fmh7tayeCkMeta } from "/app/app/pages/account/addresses/new.vue?macro=true";
import { default as index1ed6RCLl5gWRWNQTLXK_4kLEr1V3eAJFnthiM8iV_UMMeta } from "/app/app/pages/account/reviews/index.vue?macro=true";
import { default as indexc_45201t0F8wsgsw4J4OHgkXY3MgehPrRnBwh5KcYa9K4Meta } from "/app/app/pages/blog/categories/index.vue?macro=true";
import { default as _91slug_93A7FyeC3nu44RHER4vmmQVoHLwxroKOp4OzYrRR0SNJEMeta } from "/app/app/pages/blog/post/[id]/[slug].vue?macro=true";
import { default as what_45is_45microlearningpr5lAiv_455oytWYu_drc8GlfVu5fs0UDhj623T7f2SvMMeta } from "/app/app/pages/what-is-microlearning.vue?macro=true";
import { default as reauthenticateVHtGyiphGOI0ogHEYmzdolV3zQYKMVn33oJPZHdZUgoMeta } from "/app/app/pages/account/reauthenticate.vue?macro=true";
import { default as indexzaFVophxSJzEgT2t_XZhMj6KjwILw_45ZlmceBXPDS6jMMeta } from "/app/app/pages/account/sessions/index.vue?macro=true";
import { default as indexdAi0DY2iG5gbaz7vkLWs3BDOhYX64vY8N92xB9d62B0Meta } from "/app/app/pages/account/settings/index.vue?macro=true";
import { default as indexwkpb2pUbvpKup7dcJZepD5KqtjF4VDZLyKZBfFvhxUAMeta } from "/app/app/pages/account/addresses/index.vue?macro=true";
import { default as changeKz4L33IZacougH_KZAVkV8ZInfgt532YoWi5msQLfUQMeta } from "/app/app/pages/account/password/change.vue?macro=true";
import { default as signupR7anVMvgKzOWgsbh1zhXZkywksXWf80cB8JLvV9C5ZwMeta } from "/app/app/pages/account/provider/signup.vue?macro=true";
import { default as indexMASPNe_45vINl1wt_o4NzmTecrcslMSNAxqDlMgkQxI1AMeta } from "/app/app/pages/account/providers/index.vue?macro=true";
import { default as _91uuid_93c_45UdmtVdvm5gVBy7o_Cg8uU7ghjO2bnTDiC9E8H1qtcMeta } from "/app/app/pages/checkout/success/[uuid].vue?macro=true";
import { default as addmjtPbHQ1jMx6fQdwY_45SyXT2pQ2PoiVBaxRXiHKxyeHEMeta } from "/app/app/pages/account/2fa/webauthn/add.vue?macro=true";
import { default as postsjAvWsYu3FFyaT_oFNuZtMlIEJMtdj7CI3ccDkUlKPhwMeta } from "/app/app/pages/account/favourites/posts.vue?macro=true";
import { default as indexOqz3Y_VEcTu5kv8qbPR6rQFDNyXs3p7seIhwBJ3LVeYMeta } from "/app/app/pages/account/login/code/index.vue?macro=true";
import { default as activatedMTdqUamf87FupFsdnoSzu8GFda4AuAwYKr_PHs8_45JwMeta } from "/app/app/pages/account/2fa/totp/activate.vue?macro=true";
import { default as callbacktCIha9kank4kkGqff7wbgLfEqHO6KadtFJtailT6Q6UMeta } from "/app/app/pages/account/provider/callback.vue?macro=true";
import { default as _91slug_93O11SZPWuTlVIJ21R8iNrbIFerRhsaaErvSvM_45mkXiaIMeta } from "/app/app/pages/blog/category/[id]/[slug].vue?macro=true";
import { default as indexcOmTGS_KWNwFmPoUi5_45j37J2iD_QlXQQ7up7Y2QNvPUMeta } from "/app/app/pages/account/2fa/webauthn/index.vue?macro=true";
import { default as confirm4MMAk8tMdzHuidzFILqBjkzXgR_nbZoheIdIHhStxPoMeta } from "/app/app/pages/account/login/code/confirm.vue?macro=true";
import { default as _91key_93O3J7_n93RvoRt9IC8Wrkb_pxPoylVM3RkGS9g_6Q8ikMeta } from "/app/app/pages/account/verify-email/[key].vue?macro=true";
import { default as indexkCAu5WV_4548KcIAz7KtyyAwWKS2tuvGhCpjBSwuhC3soMeta } from "/app/app/pages/account/verify-email/index.vue?macro=true";
import { default as deactivatendZWY5wnnRceI2_45MhHaV1_45irbEn_45h9X8RsCJ9a76J94Meta } from "/app/app/pages/account/2fa/totp/deactivate.vue?macro=true";
import { default as editpgwzTy_oB5e2l6UrMSpgSr3oxnIWT_8024BrVlZdq8kMeta } from "/app/app/pages/account/addresses/[id]/edit.vue?macro=true";
import { default as productsAD3YfF0DqPMIzVfi5MStuq1xJGWrbSzAmH_HWMS2x8oMeta } from "/app/app/pages/account/favourites/products.vue?macro=true";
import { default as indexyFnDPRCPum3_Cddz0MbiRISa8NvCz5dNa9FJHtv__w8Meta } from "/app/app/pages/account/password/reset/index.vue?macro=true";
import { default as indexCohAVOkWoiggmUtW46rYHSR5tg5A_4565SZByXpfWXkNwMeta } from "/app/app/pages/account/signup/passkey/index.vue?macro=true";
import { default as totpt_z1inoq9BYnr5UQh3Y6WR4bcgbIbyqWIWZdTiEdQoUMeta } from "/app/app/pages/account/2fa/authenticate/totp.vue?macro=true";
import { default as createNl3zf2yICJiJViMKCDOxZPYeoZg7_45FzHPDW8fV1i_45ioMeta } from "/app/app/pages/account/signup/passkey/create.vue?macro=true";
import { default as _91slug_93_452zct2cMIYLutfHaoGT_45uwvdjhuIz7navUqnewdXIfEMeta } from "/app/app/pages/products/category/[id]/[slug].vue?macro=true";
import { default as totp9TyMKzPhwCImOEczBKmSZMYsjQVN1FkAq9Dg3ieSYxYMeta } from "/app/app/pages/account/2fa/reauthenticate/totp.vue?macro=true";
import { default as indexrW3XqDnamYLqMySp8Fu2d89MZbUONWz14tDjwLmhggkMeta } from "/app/app/pages/account/2fa/recovery-codes/index.vue?macro=true";
import { default as _91key_93i3dmmeWLNeenJpZgaTX4mfGNKvwu3v6H9IlRhYxRs_45EMeta } from "/app/app/pages/account/password/reset/key/[key].vue?macro=true";
import { default as webauthny08wcfbKyOpBYzOLBZXET6xis6xItD_rPj4CwrcxJokMeta } from "/app/app/pages/account/2fa/authenticate/webauthn.vue?macro=true";
import { default as webauthnhtnepK12L6nbJr0Ga7eBGMhbC8aZbyXa_45bjUOp2LxZYMeta } from "/app/app/pages/account/2fa/reauthenticate/webauthn.vue?macro=true";
import { default as generateQaDubaIZSV_45v2espbrr40_98ksTxQEnlpz1Ak_453XG1AMeta } from "/app/app/pages/account/2fa/recovery-codes/generate.vue?macro=true";
import { default as recovery_45codesRK0yFujwWq2cnFnlrzjoR2KmqHkN38GLWmcyGaNr9S0Meta } from "/app/app/pages/account/2fa/authenticate/recovery-codes.vue?macro=true";
import { default as recovery_45codes0HFtM6WLR_dQPDkNEJwAB2I7uzKZBwK5fVMiurVOJoIMeta } from "/app/app/pages/account/2fa/reauthenticate/recovery-codes.vue?macro=true";
import { default as component_45stubT50lyzN6s2eEOglGWojJjxr8XYZ_45IP9ep5MY_456sKOtwMeta } from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubT50lyzN6s2eEOglGWojJjxr8XYZ_45IP9ep5MY_456sKOtw } from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_2fb121eee53a428ac5c119fe1c868e46/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___el",
    path: "/about",
    meta: aboutrGPy1pFPB0rYqvDyz4_459NXlT_45DouL8moWyegH9oDWz4Meta || {},
    component: () => import("/app/app/pages/about.vue")
  },
  {
    name: "index___el",
    path: "/",
    meta: index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta || {},
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "search___el",
    path: "/search",
    meta: searchkbb43gtkc60rGLWak_E_456zSn2BTK1bKkOmKyUR_45kXcMMeta || {},
    component: () => import("/app/app/pages/search.vue")
  },
  {
    name: "vision___el",
    path: "/vision",
    meta: vision61mL6ksWghf_45Sa8kzzmTAfx897e1JecckLXDhOGtDPsMeta || {},
    component: () => import("/app/app/pages/vision.vue")
  },
  {
    name: "contact___el",
    path: "/contact",
    meta: contact9YIjszO30YiI1EF3Yrjj3j04hZuaoJnKFtoCHkd82XkMeta || {},
    component: () => import("/app/app/pages/contact.vue")
  },
  {
    name: "feedback___el",
    path: "/feedback",
    meta: feedback83JE2kFnKNHHotmsxwzwwAyW_AbO7zjINgvZrTFpFQoMeta || {},
    component: () => import("/app/app/pages/feedback.vue")
  },
  {
    name: "blog___el",
    path: "/blog",
    meta: indexv2yp_toDb5EUK8ku_45QAWpOo627WINk_45K3_nAWa_ufBUMeta || {},
    component: () => import("/app/app/pages/blog/index.vue")
  },
  {
    name: "cart___el",
    path: "/cart",
    meta: indexSEpBpmIr05bnkEWtSqU0qogkWE4UWGITltf4Uv1oBkEMeta || {},
    component: () => import("/app/app/pages/cart/index.vue")
  },
  {
    name: "terms-of-use___el",
    path: "/terms-of-use",
    meta: terms_45of_45useddy4HtTnpLg1i5X8s1J_45JvLHcEsdFOw0aJsk1bUqv_45EMeta || {},
    component: () => import("/app/app/pages/terms-of-use.vue")
  },
  {
    name: "account___el",
    path: "/account",
    meta: indexp0Wbi03LwoKhqGNl_45h_oehu2KzdCscs1ZvLTapW5e9MMeta || {},
    component: () => import("/app/app/pages/account/index.vue")
  },
  {
    name: "return-policy___el",
    path: "/return-policy",
    meta: return_45policyCjJBmTzO24FYa519iBjncfJ5fMrI_hnz6qfOeqfZ0EUMeta || {},
    component: () => import("/app/app/pages/return-policy.vue")
  },
  {
    name: "checkout___el",
    path: "/checkout",
    meta: indextuxD9i30ur3bawJlePRY341J_45J7eXdwpjTspY2246F0Meta || {},
    component: () => import("/app/app/pages/checkout/index.vue")
  },
  {
    name: "cookies-policy___el",
    path: "/cookies-policy",
    meta: cookies_45policy4yeCUyKT8F41YE81TxPQvdUAHbcxBC6cWwxwc0NWDxkMeta || {},
    component: () => import("/app/app/pages/cookies-policy.vue")
  },
  {
    name: "privacy-policy___el",
    path: "/privacy-policy",
    meta: privacy_45policyJLmAnSmMhzVS_A6UmdUL_45am7fZixvpYuV71EHhBQuPgMeta || {},
    component: () => import("/app/app/pages/privacy-policy.vue")
  },
  {
    name: "products___el",
    path: "/products",
    meta: index07ys5gwdbP_qlZ29ERLnGY0_4582NVAS9qcW_ie0wXWx0Meta || {},
    component: () => import("/app/app/pages/products/index.vue")
  },
  {
    name: "account-2fa___el",
    path: "/account/2fa",
    meta: indexCcQrHADyGM_YnfqalwfVg3mEffRBwS7EE0OkdUTzLKYMeta || {},
    component: () => import("/app/app/pages/account/2fa/index.vue")
  },
  {
    name: "why-microlearning___el",
    path: "/why-microlearning",
    meta: why_45microlearningIsOLHgf8Pz3und4XyfkOOGmATPjU_45NZ8a3zkYqTSZfYMeta || {},
    component: () => import("/app/app/pages/why-microlearning.vue")
  },
  {
    name: "account-help___el",
    path: "/account/help",
    meta: indexna0prNz5fiD5A72ljlA_H1Oo_45bTSHVpKhxCxN2EhlgsMeta || {},
    component: () => import("/app/app/pages/account/help/index.vue")
  },
  {
    name: "account-email___el",
    path: "/account/email",
    meta: indexlqlEAwtJLEgVzyR9w7vrMnJH5nmBqiiukdIq9Lw_45MAMMeta || {},
    component: () => import("/app/app/pages/account/email/index.vue")
  },
  {
    name: "account-login___el",
    path: "/account/login",
    meta: indexzvPofsv9gATW6uA4Mx1nrm5FjSbW9UCPffpzuiSLurIMeta || {},
    component: () => import("/app/app/pages/account/login/index.vue")
  },
  {
    name: "account-orders-id___el",
    path: "/account/orders/:id()",
    meta: _91id_93S_45555RQcxAinqyRMYlylv_OPiO_45gPuFVZqH_45g_41TnQMeta || {},
    component: () => import("/app/app/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders___el",
    path: "/account/orders",
    meta: index5dk_3LM81ApqKBhiv0p8YDZE12z_vcbpReq0NNYe220Meta || {},
    component: () => import("/app/app/pages/account/orders/index.vue")
  },
  {
    name: "account-signup___el",
    path: "/account/signup",
    meta: indexF_qeFqbeVaqitGKezogRkCkdrlRriQhb_451bZkVSPIGwMeta || {},
    component: () => import("/app/app/pages/account/signup/index.vue")
  },
  {
    name: "products-id-slug___el",
    path: "/products/:id()/:slug()",
    meta: _91slug_93sAt45cIB1eitU_45fDZ_SrCWJ0ZULcpOAXjlalnvyuCD8Meta || {},
    component: () => import("/app/app/pages/products/[id]/[slug].vue")
  },
  {
    name: "account-addresses-new___el",
    path: "/account/addresses/new",
    meta: newo3ZM2oQzGGnL_nXKMvtAWyIh_45DqvUnno3fmh7tayeCkMeta || {},
    component: () => import("/app/app/pages/account/addresses/new.vue")
  },
  {
    name: "account-reviews___el",
    path: "/account/reviews",
    meta: index1ed6RCLl5gWRWNQTLXK_4kLEr1V3eAJFnthiM8iV_UMMeta || {},
    component: () => import("/app/app/pages/account/reviews/index.vue")
  },
  {
    name: "blog-categories___el",
    path: "/blog/categories",
    meta: indexc_45201t0F8wsgsw4J4OHgkXY3MgehPrRnBwh5KcYa9K4Meta || {},
    component: () => import("/app/app/pages/blog/categories/index.vue")
  },
  {
    name: "blog-post-id-slug___el",
    path: "/blog/post/:id()/:slug()",
    meta: _91slug_93A7FyeC3nu44RHER4vmmQVoHLwxroKOp4OzYrRR0SNJEMeta || {},
    component: () => import("/app/app/pages/blog/post/[id]/[slug].vue")
  },
  {
    name: "what-is-microlearning___el",
    path: "/what-is-microlearning",
    meta: what_45is_45microlearningpr5lAiv_455oytWYu_drc8GlfVu5fs0UDhj623T7f2SvMMeta || {},
    component: () => import("/app/app/pages/what-is-microlearning.vue")
  },
  {
    name: "account-reauthenticate___el",
    path: "/account/reauthenticate",
    meta: reauthenticateVHtGyiphGOI0ogHEYmzdolV3zQYKMVn33oJPZHdZUgoMeta || {},
    component: () => import("/app/app/pages/account/reauthenticate.vue")
  },
  {
    name: "account-sessions___el",
    path: "/account/sessions",
    meta: indexzaFVophxSJzEgT2t_XZhMj6KjwILw_45ZlmceBXPDS6jMMeta || {},
    component: () => import("/app/app/pages/account/sessions/index.vue")
  },
  {
    name: "account-settings___el",
    path: "/account/settings",
    meta: indexdAi0DY2iG5gbaz7vkLWs3BDOhYX64vY8N92xB9d62B0Meta || {},
    component: () => import("/app/app/pages/account/settings/index.vue")
  },
  {
    name: "account-addresses___el",
    path: "/account/addresses",
    meta: indexwkpb2pUbvpKup7dcJZepD5KqtjF4VDZLyKZBfFvhxUAMeta || {},
    component: () => import("/app/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-password-change___el",
    path: "/account/password/change",
    meta: changeKz4L33IZacougH_KZAVkV8ZInfgt532YoWi5msQLfUQMeta || {},
    component: () => import("/app/app/pages/account/password/change.vue")
  },
  {
    name: "account-provider-signup___el",
    path: "/account/provider/signup",
    meta: signupR7anVMvgKzOWgsbh1zhXZkywksXWf80cB8JLvV9C5ZwMeta || {},
    component: () => import("/app/app/pages/account/provider/signup.vue")
  },
  {
    name: "account-providers___el",
    path: "/account/providers",
    meta: indexMASPNe_45vINl1wt_o4NzmTecrcslMSNAxqDlMgkQxI1AMeta || {},
    component: () => import("/app/app/pages/account/providers/index.vue")
  },
  {
    name: "checkout-success-uuid___el",
    path: "/checkout/success/:uuid()",
    meta: _91uuid_93c_45UdmtVdvm5gVBy7o_Cg8uU7ghjO2bnTDiC9E8H1qtcMeta || {},
    component: () => import("/app/app/pages/checkout/success/[uuid].vue")
  },
  {
    name: "account-2fa-webauthn-add___el",
    path: "/account/2fa/webauthn/add",
    meta: addmjtPbHQ1jMx6fQdwY_45SyXT2pQ2PoiVBaxRXiHKxyeHEMeta || {},
    component: () => import("/app/app/pages/account/2fa/webauthn/add.vue")
  },
  {
    name: "account-favourites-posts___el",
    path: "/account/favourites/posts",
    meta: postsjAvWsYu3FFyaT_oFNuZtMlIEJMtdj7CI3ccDkUlKPhwMeta || {},
    component: () => import("/app/app/pages/account/favourites/posts.vue")
  },
  {
    name: "account-login-code___el",
    path: "/account/login/code",
    meta: indexOqz3Y_VEcTu5kv8qbPR6rQFDNyXs3p7seIhwBJ3LVeYMeta || {},
    component: () => import("/app/app/pages/account/login/code/index.vue")
  },
  {
    name: "account-2fa-totp-activate___el",
    path: "/account/2fa/totp/activate",
    meta: activatedMTdqUamf87FupFsdnoSzu8GFda4AuAwYKr_PHs8_45JwMeta || {},
    component: () => import("/app/app/pages/account/2fa/totp/activate.vue")
  },
  {
    name: "account-provider-callback___el",
    path: "/account/provider/callback",
    meta: callbacktCIha9kank4kkGqff7wbgLfEqHO6KadtFJtailT6Q6UMeta || {},
    component: () => import("/app/app/pages/account/provider/callback.vue")
  },
  {
    name: "blog-category-id-slug___el",
    path: "/blog/category/:id()/:slug()",
    meta: _91slug_93O11SZPWuTlVIJ21R8iNrbIFerRhsaaErvSvM_45mkXiaIMeta || {},
    component: () => import("/app/app/pages/blog/category/[id]/[slug].vue")
  },
  {
    name: "account-2fa-webauthn___el",
    path: "/account/2fa/webauthn",
    meta: indexcOmTGS_KWNwFmPoUi5_45j37J2iD_QlXQQ7up7Y2QNvPUMeta || {},
    component: () => import("/app/app/pages/account/2fa/webauthn/index.vue")
  },
  {
    name: "account-login-code-confirm___el",
    path: "/account/login/code/confirm",
    meta: confirm4MMAk8tMdzHuidzFILqBjkzXgR_nbZoheIdIHhStxPoMeta || {},
    component: () => import("/app/app/pages/account/login/code/confirm.vue")
  },
  {
    name: "account-verify-email-key___el",
    path: "/account/verify-email/:key()",
    meta: _91key_93O3J7_n93RvoRt9IC8Wrkb_pxPoylVM3RkGS9g_6Q8ikMeta || {},
    component: () => import("/app/app/pages/account/verify-email/[key].vue")
  },
  {
    name: "account-verify-email___el",
    path: "/account/verify-email",
    meta: indexkCAu5WV_4548KcIAz7KtyyAwWKS2tuvGhCpjBSwuhC3soMeta || {},
    component: () => import("/app/app/pages/account/verify-email/index.vue")
  },
  {
    name: "account-2fa-totp-deactivate___el",
    path: "/account/2fa/totp/deactivate",
    meta: deactivatendZWY5wnnRceI2_45MhHaV1_45irbEn_45h9X8RsCJ9a76J94Meta || {},
    component: () => import("/app/app/pages/account/2fa/totp/deactivate.vue")
  },
  {
    name: "account-addresses-id-edit___el",
    path: "/account/addresses/:id()/edit",
    meta: editpgwzTy_oB5e2l6UrMSpgSr3oxnIWT_8024BrVlZdq8kMeta || {},
    component: () => import("/app/app/pages/account/addresses/[id]/edit.vue")
  },
  {
    name: "account-favourites-products___el",
    path: "/account/favourites/products",
    meta: productsAD3YfF0DqPMIzVfi5MStuq1xJGWrbSzAmH_HWMS2x8oMeta || {},
    component: () => import("/app/app/pages/account/favourites/products.vue")
  },
  {
    name: "account-password-reset___el",
    path: "/account/password/reset",
    meta: indexyFnDPRCPum3_Cddz0MbiRISa8NvCz5dNa9FJHtv__w8Meta || {},
    component: () => import("/app/app/pages/account/password/reset/index.vue")
  },
  {
    name: "account-signup-passkey___el",
    path: "/account/signup/passkey",
    meta: indexCohAVOkWoiggmUtW46rYHSR5tg5A_4565SZByXpfWXkNwMeta || {},
    component: () => import("/app/app/pages/account/signup/passkey/index.vue")
  },
  {
    name: "account-2fa-authenticate-totp___el",
    path: "/account/2fa/authenticate/totp",
    meta: totpt_z1inoq9BYnr5UQh3Y6WR4bcgbIbyqWIWZdTiEdQoUMeta || {},
    component: () => import("/app/app/pages/account/2fa/authenticate/totp.vue")
  },
  {
    name: "account-signup-passkey-create___el",
    path: "/account/signup/passkey/create",
    meta: createNl3zf2yICJiJViMKCDOxZPYeoZg7_45FzHPDW8fV1i_45ioMeta || {},
    component: () => import("/app/app/pages/account/signup/passkey/create.vue")
  },
  {
    name: "products-category-id-slug___el",
    path: "/products/category/:id()/:slug()",
    meta: _91slug_93_452zct2cMIYLutfHaoGT_45uwvdjhuIz7navUqnewdXIfEMeta || {},
    component: () => import("/app/app/pages/products/category/[id]/[slug].vue")
  },
  {
    name: "account-2fa-reauthenticate-totp___el",
    path: "/account/2fa/reauthenticate/totp",
    meta: totp9TyMKzPhwCImOEczBKmSZMYsjQVN1FkAq9Dg3ieSYxYMeta || {},
    component: () => import("/app/app/pages/account/2fa/reauthenticate/totp.vue")
  },
  {
    name: "account-2fa-recovery-codes___el",
    path: "/account/2fa/recovery-codes",
    meta: indexrW3XqDnamYLqMySp8Fu2d89MZbUONWz14tDjwLmhggkMeta || {},
    component: () => import("/app/app/pages/account/2fa/recovery-codes/index.vue")
  },
  {
    name: "account-password-reset-key-key___el",
    path: "/account/password/reset/key/:key()",
    meta: _91key_93i3dmmeWLNeenJpZgaTX4mfGNKvwu3v6H9IlRhYxRs_45EMeta || {},
    component: () => import("/app/app/pages/account/password/reset/key/[key].vue")
  },
  {
    name: "account-2fa-authenticate-webauthn___el",
    path: "/account/2fa/authenticate/webauthn",
    meta: webauthny08wcfbKyOpBYzOLBZXET6xis6xItD_rPj4CwrcxJokMeta || {},
    component: () => import("/app/app/pages/account/2fa/authenticate/webauthn.vue")
  },
  {
    name: "account-2fa-reauthenticate-webauthn___el",
    path: "/account/2fa/reauthenticate/webauthn",
    meta: webauthnhtnepK12L6nbJr0Ga7eBGMhbC8aZbyXa_45bjUOp2LxZYMeta || {},
    component: () => import("/app/app/pages/account/2fa/reauthenticate/webauthn.vue")
  },
  {
    name: "account-2fa-recovery-codes-generate___el",
    path: "/account/2fa/recovery-codes/generate",
    meta: generateQaDubaIZSV_45v2espbrr40_98ksTxQEnlpz1Ak_453XG1AMeta || {},
    component: () => import("/app/app/pages/account/2fa/recovery-codes/generate.vue")
  },
  {
    name: "account-2fa-authenticate-recovery-codes___el",
    path: "/account/2fa/authenticate/recovery-codes",
    meta: recovery_45codesRK0yFujwWq2cnFnlrzjoR2KmqHkN38GLWmcyGaNr9S0Meta || {},
    component: () => import("/app/app/pages/account/2fa/authenticate/recovery-codes.vue")
  },
  {
    name: "account-2fa-reauthenticate-recovery-codes___el",
    path: "/account/2fa/reauthenticate/recovery-codes",
    meta: recovery_45codes0HFtM6WLR_dQPDkNEJwAB2I7uzKZBwK5fVMiurVOJoIMeta || {},
    component: () => import("/app/app/pages/account/2fa/reauthenticate/recovery-codes.vue")
  },
  {
    name: component_45stubT50lyzN6s2eEOglGWojJjxr8XYZ_45IP9ep5MY_456sKOtwMeta?.name,
    path: "/index",
    component: component_45stubT50lyzN6s2eEOglGWojJjxr8XYZ_45IP9ep5MY_456sKOtw
  },
  {
    name: component_45stubT50lyzN6s2eEOglGWojJjxr8XYZ_45IP9ep5MY_456sKOtwMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubT50lyzN6s2eEOglGWojJjxr8XYZ_45IP9ep5MY_456sKOtw
  }
]